import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import '@lottiefiles/lottie-player';
import { useSelector } from 'react-redux';

const About = () => {
  const { loading, portfolioData } = useSelector((state) => state.root);
  const { about } = portfolioData;
  const { skills, lottieURL, resumeURL, description1, description2 } = about;

  return (
    <div id='about'>
      <SectionTitle title='About' />
      <div className='flex w-full items-center md:flex-col'>
        <div className='h-[60vh] w-1/2 md:w-full '>
          <lottie-player
            src={lottieURL}
            background='transparent'
            speed='1'
            autoplay
            loop
          />
        </div>
        <div className='flex flex-col gap-5 w-1/2 md:w-full'>
          <p className='text-white'>{description1 || ''}</p>
          <p className='text-white'>{description2 || ''}</p>
          <a
            href={resumeURL}
            target='_blank'
            rel='noopener noreferrer'
            className=''
          >
            <button className='border-2 btn1 border-tertiary text-tertiary px-10 py-3 rounded '>
              {/* <i className='ri-arrow-right-up-line border-solid	'></i> */}
              <i class='ri-download-2-fill'></i>
              &nbsp;Download CV
            </button>
          </a>
        </div>
      </div>

      <div className='py-5'>
        <h1 className='text-tertiary text-xl'>
          Here are a few technologies I’ve been working with recently:
        </h1>
        <div className='flex flex-wrap gap-10 mt-5'>
          {skills.map((skill, index) => (
            <div key={index} className=' animated-button1'>
              {/* <h1 className='text-tertiary'></h1> */}
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {skill}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
