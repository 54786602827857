import React from 'react';

function Footer() {
  return (
    <div className='py-10'>
      <div className='h-[1px] w-full bg-gray-700'></div>

      <div className='flex items-center justify-center flex-col mt-10 opacity-70'>
        <h1 className='text-white'>Designed and Developed By</h1>
        <a href='https://www.linkedin.com/in/omkrjha/'  target='_blank'
          rel='noopener noreferrer'>
          <h5 className='text-white'>
            <span className='text-white'>@ omkrjha</span>
          </h5>
        </a>
      </div>
    </div>
  );
}

export default Footer;
