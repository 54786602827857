import React from 'react';
import SectionTitle from '../../../components/SectionTitle';
import Form from './Form';
import { useSelector } from 'react-redux';

const Contact = () => {
  const { portfolioData } = useSelector((state) => state.root);
  const { contact } = portfolioData;
  console.log(contact);
  return (
    <div id='contact'>
      <SectionTitle title='Say Hello' />
      <div className='flex md:flex-col  items-center justify-between'>
        <div className='flex flex-col w-1/2  sm:w-full'>
          {/* {Object.keys(user).map(
            (key) =>
              key !== '_id' && (
                <p className='ml-5'>
                  <span className='text-tertiary'>{key} : </span>
                  <span className='text-tertiary'>{user[key]}</span>
                </p>
              )
          )} */}

          <Form />
        </div>
        <div className='h-full w-2/5 right-0 border top-0 p-5 m-5 flex md:flex-col items-center justify-between sm:w-full text-gray-100'>
          <div className=''>
            <h4 className='text-2xl	'>
              If you have any query or suggestion i would be happy to answer
              them, feel free to contact me.
            </h4>
            <div className='flex flex-row md:flex-col font-bold	gap-2 p-3'>
              <div className='w-auto p-1'>
                {' '}
                <span className='text'>
                  <i className='ri-phone-fill p-2'></i>
                  {contact.mobile}
                </span>
              </div>
              <div className='w-auto  p-1'>
                <span className='bold'>
                  <i className='ri-mail-fill p-2'></i>
                  {contact.email}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
