import React from 'react';
import { useSelector } from 'react-redux';
import { Typewriter } from 'react-simple-typewriter';
import { scrollToSection } from '../../utils/helper';

const Intro = () => {
  const { loading, portfolioData } = useSelector((state) => state.root);
  const { intro } = portfolioData;
  const {
    firstName,
    lastName,
    welcomeText,
    description,
    caption,
    caption1,
    caption2,
    lottieUrl,
  } = intro;

  return (
    <div className='flex w-full items-center md:flex-col '>
      <div className='h-[83vh] w-3/5  flex flex-col items-start justify-center gap-8 py-10 sm:w-full md:w-full '>
        <h1 className='text-white'>{welcomeText || ''} </h1>
        <h1 className='text-6xl  md:text-5xl sm:text-4xl text-secondary font-semibold'>
          {firstName || ''} {lastName || ''}
        </h1>
        <h1 className='text-4xl md:text-4xl sm:text-3xl text-white font-semibold'>
          {/* {caption || ''} */}
          <Typewriter
            words={[`${caption || ''}`, `${caption1 || ''}`]}
            loop={0}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={2000}
          />
        </h1>
        <p className='text-white w-2/3'>{description || ''}</p>
        {/* <button className='border-2 btn border-tertiary text-tertiary px-10 py-3 rounded '>
          Get Started
        </button> */}

        <button
          onClick={() => scrollToSection('contact')}
          className='bg-blue-500 btn1 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300'
        >
          Let's Talk
        </button>
      </div>
      {/*  */}
      <div className='h-[70vh] w-2/5  sm:w-full md:w-2/3'>
        <lottie-player
          src={lottieUrl}
          background='transparent'
          speed='1'
          loop
          autoplay
        />
      </div>
    </div>
  );
};

export default Intro;
//
