import React from 'react';

const Header = () => {
  return (
    <div className='p-5 flex justify-between'>
      <a href='/'>
        <h1 className='text-secondary text-4xl font-semibold'>H</h1>
      </a>
      <a href='/'>
        <h1 className='text-white text-4xl font-semibold'>K</h1>
      </a>
      <a href='/'>
        <h1 className='text-secondary text-4xl font-semibold'>J</h1>
      </a>
    </div>
  );
};

export default Header;
