import React from 'react';

const LeftSider = () => {
  return (
    <div className='fixed left-0 bottom-0 text-3xl	 px-10 md:static'>
      <div className='flex flex-col items-center'>
        <div className='flex flex-col gap-3 md:flex-row'>
          <a
            href='https://omkrjha.netlify.app/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {' '}
            <i class='ri-global-line text-gray-400'></i>
          </a>
          <a
            href='https://www.linkedin.com/in/omkrjha/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='ri-linkedin-box-line text-gray-400 '></i>
          </a>
          <a
            href='mailto:omkrjha@outlook.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='ri-mail-line text-gray-400 '></i>
          </a>
          {/* <a href='instagram.com' target='_blank'
            rel='noopener noreferrer'>
            <i className='ri-instagram-line text-gray-400 '></i>
          </a> */}
         
          <a
            href='https://github.com/omkrjha'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='ri-github-line text-gray-400 '></i>
          </a>
        </div>
        <div className='w-[1px] h-32 bg-[#125f63] md:hidden'></div>
      </div>
    </div>
  );
};

export default LeftSider;
