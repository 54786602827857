import React from 'react';
import { send } from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    send(
      'service_udbce57', // Service ID
      'template_mvrb4rg', // Template ID
      data,
      'aJO0HyMz_BjCXjgow' // Public Key
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        formSuccess();
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  const formSuccess = () => {
    toast('Thanks for submitting your Query!');
    document.getElementById('queryForm').reset();
  };

  return (
    <div className=''>
      <ToastContainer />
      <form id='queryForm' onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-4'>
          <input
            type='text'
            name='from_name'
            placeholder='Name*'
            {...register('from_name', {
              required: '*Name is required',
            })}
            className='w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300'
          />
          {errors.from_name?.message && (
            <p className='text-orange-400'>{errors.from_name?.message}</p>
          )}
        </div>

        <div className='mb-4 	'>
          <input
            className='w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300'
            type='text'
            name='reply_to'
            placeholder='Email*'
            {...register('reply_to', {
              required: '*Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.reply_to?.message && (
            <p className='text-orange-400'>{errors.reply_to?.message}</p>
          )}
        </div>

        <div className='mb-4'>
          <input
            className='w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300'
            type='text'
            name='phone_number'
            placeholder='Phone'
            // {...register("phone_number", {
            //     required: "Phone number is required",
            //     minLength: {
            //         value: 8,
            //         message: "Phone number is not valid",
            //     },
            // })}
          />
          {errors.phone_number?.message && (
            <p className='error'>{errors.phone_number?.message}</p>
          )}
        </div>

        <div className='mb-4 text-gray-700'>
          <input
            className='w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300'
            type='text'
            name='subject'
            placeholder='Subject*'
            {...register('subject', {
              required: '*Subject is required',
              minLength: {
                value: 10,
                message: 'Minimum 10 characters required',
              },
            })}
          />
          {errors.subject?.message && (
            <p className='error text-orange-400'>{errors.subject?.message}</p>
          )}
        </div>

        <div className='mb-4 block text-gray-700 font-medium'>
          <textarea
            className='w-full h-36 px-4 py-2 border rounded-md focus:ring focus:ring-blue-300'
            name='message'
            placeholder='Your message*'
            {...register('message', {
              required: '*Message is required',
              minLength: {
                value: 20,
                message: 'Minimum 20 characters required',
              },
              maxLength: {
                value: 500,
                message: 'Maximum 500 characters allowed',
              },
            })}
          />
          {errors.message?.message && (
            <p className='text-orange-400'>{errors.message?.message}</p>
          )}
        </div>

        <button
          type='submit'
          className='bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300'
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
