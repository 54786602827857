import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, message } from 'antd';
import { HideLoading, ReloadData, ShowLoading } from '../../redux/rootSlice';
import axios from 'axios';

const AdminExperiences = () => {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const { experiences } = portfolioData;

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);

  const [type, setType] = useState('add');

  const onFinish = async (values) => {
    // console.log(values);
    try {
      dispatch(ShowLoading());
      let response;
      // after creating "/add-experience" api in portfolioRoutes we came here to send request to that api with payload(id, values)
      if (selectedItemForEdit) {
        //if we are editing
        response = await axios.post('/api/portfolio/update-experience', {
          ...values,
          _id: selectedItemForEdit._id,
        });
      } else {
        //if we are adding new item
        response = await axios.post(
          '/api/portfolio/add-experience',
          values //these are payload
        );
      }
      dispatch(HideLoading());

      if (response.data.success) {
        message.success(response.data.message);
        setShowAddEditModal(false);
        setSelectedItemForEdit(null);
        dispatch(HideLoading());
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const onDelete = async (item) => {
    try {
      dispatch(ShowLoading());
      const response = await axios.post('/api/portfolio/delete-experience', {
        _id: item._id,
      });
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(HideLoading());
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  return (
    <div>
      <div className='flex justify-end'>
        <button
          className='bg-primary px-5 py-2 text-white'
          onClick={() => {
            setType('add');
            setShowAddEditModal(true);
            setSelectedItemForEdit(null);
          }}
        >
          Add Experience
        </button>
      </div>
      <div className='grid grid-cols-4 gap-5 mt-5 sm:grid-cols-1'>
        {experiences.map((experience, index) => {
          return (
            <div
              key={index}
              className='shadow border border-gray-400 p-5 flex flex-col'
            >
              <h1 className='text-primary text-xl font-bold p-1'>
                {experience.period}
              </h1>
              <hr />
              <h1 className='p-1'>Company : {experience.company}</h1>
              <h1 className='p-1'>Role : {experience.title}</h1>
              <h1 className='p-1'>{experience.description}</h1>
              <div className='flex justify-end gap-5 mt-5'>
                <button
                  className='bg-red-500 text-white px-5 py-2 rounded-md'
                  onClick={() => onDelete(experience)}
                >
                  Delete
                </button>
                <button
                  type='submit'
                  className='bg-primary text-white px-5 py-2 rounded-md'
                  onClick={() => {
                    setSelectedItemForEdit(experience);
                    setShowAddEditModal(true);
                    setType('edit');
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {(type === 'add' || selectedItemForEdit) && (
        <Modal
          footer={null}
          open={showAddEditModal}
          title={selectedItemForEdit ? 'Edit Experience' : 'Add Experience'}
          onCancel={() => {
            setShowAddEditModal(false);
            setSelectedItemForEdit(null);
          }}
        >
          <Form
            layout='vertical'
            onFinish={onFinish}
            initialValues={selectedItemForEdit || {}}
          >
            <Form.Item>
              <Form.Item name='period' label='Period'>
                <input placeholder='Period' />
              </Form.Item>
              <Form.Item name='company' label='Company'>
                <input placeholder='Company' />
              </Form.Item>
              <Form.Item name='title' label='Title'>
                <input placeholder='Title' />
              </Form.Item>
              <Form.Item name='description' label='Description'>
                <textarea placeholder='Description' />
              </Form.Item>
            </Form.Item>

            <div className='flex justify-end'>
              <button
                className='border-primary text-primary px-5 py-2'
                onClick={() => {
                  setShowAddEditModal(false);
                  setSelectedItemForEdit(null);
                }}
              >
                Cancel
              </button>
              <button className='bg-primary text-white px-5 py-2'>
                {selectedItemForEdit ? 'Update' : 'Add'}
              </button>
            </div>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default AdminExperiences;
