import React from 'react';
import { scrollToSection } from '../../utils/helper';



const RightSidebar = () => {
  return (
    <div className='fixed right-0  bottom-0 center text-4xl	 px-10 md:p-2 md:bottom-3'>
      <div className='flex flex-col top-14 items-center'>
        <div className='flex  flex-col gap-3 '>
          <div className='' onClick={() => scrollToSection('home')}>
            <i className='ri-home-3-line text-yellow-600	'></i>
          </div>

          <div className='' onClick={() => scrollToSection('about')}>
            <i className='ri-user-follow-line text-gray-400'></i>
          </div>

          <div onClick={() => scrollToSection('experiences')}>
            <i className='text-gray-400'>
            <i class="ri-briefcase-2-line"></i>
            </i>
          </div>

          <div className='' onClick={() => scrollToSection('projects')}>
            <i className='ri-file-chart-line text-gray-400'></i>
          </div>

          <div className='' onClick={() => scrollToSection('contact')}>
            <i className='ri-contacts-line text-gray-400'></i>
          </div>
        </div>
        <div className='w-[1px] h-32 bg-[#2c898e] md:hidden'></div>
      </div>
    </div>
  );
};

export default RightSidebar;
