import React from 'react';
import { Link } from 'react-router-dom';

const ProjectItem = ({ index, img, title, desc, gitLink, live, tech }) => {
  const tempSkills = String(tech).split(',');
  tech = tempSkills;
  return (
    <div id={index}>
      <h1 className='font-bold text-xl mb-2 underline text-blue-600	pb-2 '>
        {title}
      </h1>
      <img
        className='w-full h-64 p-2 border-tertiary'
        src={img}
        alt='project img '
      />
      <p className='h-46 text-gray-100	mt-2	 text-base"'>{desc}</p>
      {/* technologies */}
      <div className='flex  flex-wrap text-gray-100 justify-center gap-3 mt-5'>
        {tech.map((skill, index) => (
          <div
            key={index}
            className='text-1xl rounded border border-double	 w-auto p-1.5  border-slate-300	text-tertiary'
          >
            <p>{skill}</p>
          </div>
        ))}
      </div>
      {/*  */}
      &nbsp;
      <div class=' flex justify-center items-center gap-5'>
        <a
          href={gitLink}
          target='_blank'
          rel='noopener noreferrer'
          class='group relative'
        >
          <button class='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-300 transform scale-100 group-hover:scale-110'>
            <i className='text-2xl hover:bg-white	 ri-github-fill'></i>
          </button>
        </a>
        <a
          href={live}
          target='_blank'
          rel='noopener noreferrer'
          class='group relative'
        >
          <button class='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-300 transform scale-100 group-hover:scale-110'>
            <i className='text-2xl hover:bg-white ri-code-box-fill'></i>
          </button>
        </a>
      </div>
    </div>
  );
};

export default ProjectItem;
