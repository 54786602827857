import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle';
// import { experiences } from '../../resources/experiences';
import { useSelector } from 'react-redux';

const Experiences = () => {
  const [activeIndex, setactiveIndex] = useState(0);
  const { portfolioData } = useSelector((state) => state.root);
  const { experiences } = portfolioData;
  return (
    <div id='experiences'>
      <SectionTitle title='Experience' />
      <div className='flex py-10   md:flex-col place-content-center md:h-auto'>
        <div className='flex flex-col gap-8  place-content-center border-l-2 w-2/7  border-[#135e4c82] w-1/5 md:flex-row  md:w-full '>
          {experiences.map((experience, index) => (
            <div
              onClick={() => {
                setactiveIndex(index);
              }}
              key={index}
              className='cursor-pointer'
            >
              <h1
                className={`text-xl px-5 sm:text-xs sm:px-2 md:px-3
                 ${
                   activeIndex === index
                     ? 'text-tertiary border-tertiary border-l-4 -ml-[3px] bg-[#1a7f5a31] py-3'
                     : 'text-white'
                 } `}
              >
                {experience.period}
              </h1>
            </div>
          ))}
        </div>

        <div className='flex p-5 flex-col w-4/5 gap-5 sm:w-full md:w-full'>
          <h1 className='text-secondary text-xl'>
            {experiences[activeIndex].title}
          </h1>
          <h1 className='text-tertiary text-xl'>
            {experiences[activeIndex].company}
          </h1>
          <p className='text-white'>{experiences[activeIndex].description}</p>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
