import React from 'react';
import Header from '../../components/Header';
import Intro from './Intro';
import About from './About';
import Experiences from './Experiences';
import Projects from './Projects';
import Contact from './form/Contact';
import Footer from './Footer';
import LeftSider from './LeftSider';
import RightSidebar from './RightSidebar';
import { useSelector } from 'react-redux';

const Home = () => {
  const { portfolioData } = useSelector((state) => state.root);
  console.log(portfolioData);
  return (
    <div className='' id='home'>
      <Header />
      {portfolioData && (
        <div className='px-32 md:px-6'>
          <Intro />
          <About />
          <Experiences />
          <Projects />
          <Contact />
          <Footer />
          <LeftSider />
          <RightSidebar />
        </div>
      )}
    </div>
  );
};

export default Home;
