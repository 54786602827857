import React, { useEffect, useState } from 'react';
import SectionTitle from '../../components/SectionTitle';
import Header from '../../components/Header';
// import { projects } from '../../resources/projects';
import ProjectItem from './ProjectItem';
import Footer from './Footer';
import { useSelector } from 'react-redux';

const ProjectPage = () => {
  const [searchText, setSearchText] = useState('');
  const { portfolioData } = useSelector((state) => state.root);


  const { projects } = portfolioData;
  const [projectsData, setProjectsData] = useState([...projects].reverse());

  // console.log(projects);

 
  useEffect(() => {
    if (searchText === '') return;
    setProjectsData(() =>
      projects.filter(
        (item) =>
          item.desc.toLowerCase().match(searchText) ||
          item.title.toLowerCase().match(searchText)
      )
    );
  }, [searchText]);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value.toLowerCase());
    if (!e.target.value.length > 0) {
      setProjectsData(projects);
    }
  };

  return (
    <div className=' p-4'>
      <Header />
      <SectionTitle title='Projects' />
      <div className=''>
        <div className='ml-3 flex items-center border bg-gray-100 w-72 rounded-md p-2'>
          <input
            // className='p-2 w-full focus:marker:first-letter'
            className='border-none outline-none bg-gray-100 flex-grow '
            type='text'
            value={searchText}
            onChange={handleChange}
            placeholder='Project Name'
          />
          <i className='text-2xl ri-search-line'></i>
        </div>
        <div 
        
        className='grid grid-cols-3 p-5  gap-5 mt-5 sm:grid-cols-1 md:grid-cols-2'
        >
          {projectsData &&
            projectsData.map((project, index) => (
              <div
                key={index}
              
                className='project rounded-lg border p-5  flex flex-col  gap-5 transition ease-in-out delay-150  hover:-translate-y-0.2 hover:scale-105 duration-300 ...
                '
              >
                <ProjectItem
                  key={project.id}
                  title={project.title}
                  desc={project.desc}
                  img={project.image}
                  gitLink={project.gitLink}
                  live={project.live}
                  tech={project.tech}
                />
              </div>
            ))}
        </div>
      </div>
      {/*  */}
      <div className='fixed right-0  bottom-0 center text-4xl	 px-10 md:p-2 md:bottom-3'>
        <div className='flex flex-col top-14 items-center'>
          <div className='flex  flex-col gap-3 '>
            <a href='/'>
              <i className='ri-home-3-line text-yellow-600	'></i>
            </a>
          </div>
          <div className='w-[1px] h-32 bg-[#125f63] md:hidden'></div>
        </div>
      </div>

      {/*  */}
      <Footer />
    </div>
  );
};

export default ProjectPage;
