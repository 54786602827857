import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle';

import { useSelector } from 'react-redux';

const Projects = () => {
  const [activeIndex, setactiveIndex] = useState(0);
  const { portfolioData } = useSelector((state) => state.root);
  const { projects } = portfolioData;

  const maxItemsToShow = 4;
  const limitedItems = [...projects].reverse().slice(0, maxItemsToShow);
  return (
    <div id='projects' className=''>
      <SectionTitle title='Projects' />
      <>
        <div className='flex py-10 h-96  gap-7 md:flex-col md:h-auto'>
          <div className='flex flex-col  gap-8 border-l-2 w-2/7 border-[#135e4c82]  md:flex-row md:overflow-x-scroll md:w-full md:overflow-y-hidden '>
            {limitedItems.map((project, index) => (
              <div
                onClick={() => {
                  setactiveIndex(index);
                }}
                key={index}
                className='cursor-pointer md:place-content-center sm:gap-4'
              >
                <h1
                  className={`text-xl px-5 sm:text-xs sm:px-2 md:px-3 
                 ${
                   activeIndex === index
                     ? 'text-tertiary border-tertiary border-l-4 -ml-[3px] bg-[#1a7f5a31] py-3'
                     : 'text-white'
                 } `}
                >
                  {project.title}
                </h1>
              </div>
            ))}
          </div>

          <div className='flex w-5/6 items-center justify-center md:place-content-center gap-10 md:flex-col'>
            <img
              src={limitedItems[activeIndex].image}
              alt=''
              className='h-72 w-2/5 md:w-1/2 sm:w-full justify-center '
            />
            <div className='flex flex-col w-3/5 gap-5 md:w-full'>
              <h1 className='text-secondary text-xl'>
                {limitedItems[activeIndex].title}
              </h1>
              <p className='text-white'>{limitedItems[activeIndex].desc}</p>

              {/*  */}
              <div class=' flex justify-center items-center gap-5'>
                <a
                  href={limitedItems[activeIndex].gitLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  class='group relative'
                >
                  <button class='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-300 transform scale-100 group-hover:scale-110'>
                    <i className='text-2xl hover:bg-white	 ri-github-fill'></i>
                  </button>
                </a>
                <a
                  href={limitedItems[activeIndex].live}
                  target='_blank'
                  rel='noopener noreferrer'
                  class='group relative'
                >
                  <button class='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-300 transform scale-100 group-hover:scale-110'>
                    <i className='text-2xl hover:bg-white ri-code-box-fill'></i>
                  </button>
                </a>
              </div>

              {/*  */}
            </div>
          </div>
        </div>

        <a href='/projects' className='justify-center animated-button1 w-auto'>
          &nbsp;{' '}
          {/* <button className='border-2 border-tertiary w-auto text-tertiary p-1.5 hover:text-red-50 bg-black rounded min-w-min '>
            </button> */}
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          More Projects
        </a>
      </>
    </div>
  );
};

export default Projects;
