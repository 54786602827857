import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, message } from 'antd';
import { HideLoading, ReloadData, ShowLoading } from '../../redux/rootSlice';
import axios from 'axios';

const AdminProjects = () => {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const { projects } = portfolioData;

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);

  const [type, setType] = useState('add');

  const onFinish = async (values) => {
    // console.log(values);
    try {
      const tempTechnologies = values?.technologies?.split(',') || [];
      values.technologies = tempTechnologies;
      dispatch(ShowLoading());
      let response;
      // after creating "/add-project" api in portfolioRoutes we came here to send request to that api with payload(id, values)
      if (selectedItemForEdit) {
        //if we are editing
        response = await axios.post('/api/portfolio/update-project', {
          ...values,
          _id: selectedItemForEdit._id,
        });
      } else {
        //if we are adding new item
        response = await axios.post(
          '/api/portfolio/add-project',
          values //these are payload
        );
      }
      dispatch(HideLoading());

      if (response.data.success) {
        message.success(response.data.message);
        setShowAddEditModal(false);
        setSelectedItemForEdit(null);
        dispatch(HideLoading());
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const onDelete = async (item) => {
    try {
      dispatch(ShowLoading());
      const response = await axios.post('/api/portfolio/delete-Project', {
        _id: item._id,
      });
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(HideLoading());
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
 
  return (
    <div>
      <div className='flex justify-end'>
        <button
          className='bg-primary px-5 py-2 text-white'
          onClick={() => {
            setType('add');
            setShowAddEditModal(true);
            setSelectedItemForEdit(null);
          }}
        >
          Add Project
        </button>
      </div>
      <div className='grid grid-cols-3 gap-5 mt-5 sm:grid-cols-1 md:grid-cols-2'>
        {[...projects].reverse().map((project) => (
          <div className='shadow border p-5 border-gray-400 flex flex-col gap-5'>
            <h1 className='text-primary text-xl font-bold'>{project.title}</h1>
            <hr />
            <img src={project.image} alt='' className='h-60 w-full' />
            <h1>Title : {project.title}</h1>
            <h1>{project.desc}</h1>
            <div className='flex justify-start gap-5 mt-5'>
              <a
                className='bg-red-500 text-white px-5 py-2'
                href={project.gitLink}
              >
                <button>
                  <i className='ri-github-fill'></i>
                </button>
              </a>

              <a
                className='bg-primary text-white px-5 py-2'
                href={project.live}
              >
                <button>
                  <i className='ri-code-box-fill'></i>
                </button>
              </a>
            </div>
            <div className='flex justify-end gap-5 mt-5'>
              <button
                className='bg-red-500 text-white px-5 py-2 '
                onClick={() => {
                  onDelete(project);
                }}
              >
                Delete
              </button>
              <button
                className='bg-primary text-white px-5 py-2'
                onClick={() => {
                  setSelectedItemForEdit(project);
                  setShowAddEditModal(true);
                  setType('edit');
                }}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>

      {(type === 'add' || selectedItemForEdit) && (
        <Modal
          footer={null}
          open={showAddEditModal}
          title={selectedItemForEdit ? 'Edit Project' : 'Add Project'}
          onCancel={() => {
            setShowAddEditModal(false);
            setSelectedItemForEdit(null);
          }}
        >
          <Form
            layout='vertical'
            onFinish={onFinish}
            initialValues={
              {
                ...selectedItemForEdit,
                technologies: selectedItemForEdit?.technologies?.join(' , '),
              } || {}
            }
          >
            <Form.Item name='title' label='Title'>
              <input placeholder='Title' />
            </Form.Item>
            <Form.Item name='image' label='Image URL'>
              <input placeholder='Image' />
            </Form.Item>
            <Form.Item name='desc' label='Description'>
              <textarea placeholder='Description' />
            </Form.Item>
            <Form.Item name='gitLink' label='Github Link'>
              <input placeholder='Link' />
            </Form.Item>
            <Form.Item name='live' label='Deployed Link'>
              <input placeholder='Link' />
            </Form.Item>
            <Form.Item name='tech' label='Technologies'>
              <input placeholder='Technologies' />
            </Form.Item>

            <div className='flex justify-end'>
              <button
                className='border-primary text-primary px-5 py-2'
                onClick={() => {
                  setShowAddEditModal(false);
                  setSelectedItemForEdit(null);
                }}
              >
                Cancel
              </button>
              <button className='bg-primary text-white px-5 py-2'>
                {selectedItemForEdit ? 'Update' : 'Add'}
              </button>
            </div>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default AdminProjects;
